import { createSlice } from '@reduxjs/toolkit';

const adminSlice = createSlice({
  name: 'admin',
  initialState: {
    selectedPage:"Test Results",
    resultsData:[],
    columnVisibility:[],
    columnVisibilityModel: {
      "__check__": true,
      "distributor": true,
      "client": false,
      "clientAddress": false,
      "createdDate": true,
      "informationSheetNumber": true,
      "sampleIDs": true,
      "field": true,
      "sampleDepth": true,
      "moduleDescription": true,
      "yieldGoal": true,
      "clientIdentifier": true,
      "organicMatter": true,
      "saturationIndex": true,
      "pH": false,
      "sodiumAbsorptionRatio": false,
      "electricalConductivity": false,
      "excessCarbonates": false,
      "calciumTotal": false,
      "calciumAvailable": false,
      "magnesiumTotal": false,
      "magnesiumAvailable": false,
      "calciumMagnesiumRatioTotal": false,
      "potassiumTotal": false,
      "potassiumAvailable": false,
      "sodiumTotal": false,
      "sodiumAvailable": false,
      "cationExchangeCapacity": false,
      "calciumPercentBaseSaturation": false,
      "magnesiumPercentBaseSaturation": false,
      "potassiumPercentBaseSaturation": false,
      "sodiumPercentBaseSaturation": false,
      "hydrogenPercentBaseSaturation": false,
      "phosphorusTotal": false,
      "phosphorusAvailable": false,
      "sulfurTotal": false,
      "sulfateAvailable": false,
      "chlorideAvailable": false,
      "bicarbonateAvailable": false,
      "nitrogenTotal": false,
      "nitrateAvailable": false,
      "ammoniumAvailable": false,
      "estimatedNitrogenRelease": false,
      "ironTotal": false,
      "ironAvailable": false,
      "manganeseTotal": false,
      "manganeseAvailable": false,
      "zincTotal": false,
      "zincAvailable": false,
      "copperTotal": false,
      "copperAvailable": false,
      "boronAvailable": false,
      "siliconAvailable": false,
      "nRec": false,
      "p2O5Rec": false,
      "k2ORec": false,
      "mgRec": false,
      "sRec": false,
      "caRec": false,
      "znRec": false,
      "mnRec": false,
      "cuRec": false,
      "feRec": false,
      "bRec": false,
      "soilAMCa": false,
      "soilAMMg": false,
      "soilAMK2O": false,
      "actions": true
  },  // Renamed from columnVisibility to columnVisibilityModel

    changed: false,
  },
  reducers: {
    
    addSelectedPage(state, action) {
      const newItem = action.payload;
      state.changed = true;
      state.selectedPage = newItem.selectedPage;
    },
 
    addParsedCSVResult(state, action) {
      const newItem = action.payload;
      state.changed = true;
      state.resultsData = newItem.resultsData;
    },
    setColumnVisibilityModel(state, action) {  // Renamed to better reflect the action's purpose
      state.changed = true;
      state.columnVisibilityModel = action.payload.columnVisibilityModel;
    },
    
    addColumnVisibility(state, action) {
        const newItem = action.payload;
        state.changed = true;
        state.columnVisibility = newItem.columnVisibility;
      },
    

  },
});

export const adminActions = adminSlice.actions;

export default adminSlice;
